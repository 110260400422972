@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&family=Montserrat:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
   @apply bg-white
  }
  .dark body {
    @apply bg-on-white
  }
}
